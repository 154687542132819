import React from "react";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./index";
import components from "../designSystem";
import SEO from "../components/seo";

function DefinitionLayout({ data }) {
  const { mdx } = data;
  // const { next, previous } = pageContext;
  return (
    <MDXProvider components={components}>
      <Layout>
        <SEO keywords={[`${mdx.frontmatter.title}`, `What is ${mdx.frontmatter.title}?`, "Product Management Glossary"]} title={`What is ${mdx.frontmatter.title}?`} />
        <section className="flex flex-col w-full items-center justify-center">
          <div className="w-full text-center p-4">
            <h1 className="text-xl sm:text-4xl text-black font-bold tracking-wide">
              What is {mdx.frontmatter.title}?
            </h1>
          </div>
          <div className="w-full max-w-6xl text-black py-10 text-lg tracking-wide">
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
          {/* <div className="w-full max-w-6xl flex sm:flex-row flex-col justify-between items-center p-4">
            <div className="p-2 m-2 inline-flex flex-col">
              {previous && (
                <>
                  <p>Previous Term</p>
                  <Link
                    className="text-mph-orange hover:text-mph-ocean-blue font-bold"
                    href={`/glossary/${previous.frontmatter.slug}`}
                  >
                    {previous.frontmatter.title}
                  </Link>
                </>
              )}
            </div>
            <div className="p-2 m-2 sm:text-right inline-flex flex-col">
              {next && (
                <>
                  <p>Next Term</p>
                  <Link
                    className="text-mph-orange hover:text-mph-ocean-blue font-bold"
                    href={`/glossary/${next.frontmatter.slug}`}
                  >
                    {next.frontmatter.title}
                  </Link>
                </>
              )}
            </div>
          </div> */}
        </section>
      </Layout>
    </MDXProvider>
  );
}

DefinitionLayout.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query DefinitionQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
      }
    }
  }
`;

export default DefinitionLayout;
